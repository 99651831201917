
import { defineComponent } from "vue";

export default defineComponent({
  name: "view-users-modal",
  components: {},
  setup() {
    const users = [
      {
        avatar: "media/avatars/300-6.jpg",
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        position: "Art Director",
        sales: "23,000",
      },
      {
        state: "danger",
        name: "Melody Macy",
        email: "melody@altbox.com",
        position: "Marketing Analytic",
        sales: "50,500",
      },
      {
        avatar: "media/avatars/300-1.jpg",
        name: "Max Smith",
        email: "max@kt.com",
        position: "Software Enginer",
        sales: "75,900",
      },
      {
        avatar: "media/avatars/300-1.jpg",
        name: "Sean Bean",
        email: "sean@dellito.com",
        position: "Web Developer",
        sales: "10,500",
      },
      {
        avatar: "media/avatars/300-25.jpg",
        name: "Brian Cox",
        email: "brian@exchange.com",
        position: "UI/UX Designer",
        sales: "20,000",
      },
      {
        state: "warning",
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        position: "Head Of Marketing",
        sales: "9,300",
      },
      {
        avatar: "media/avatars/300-9.jpg",
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        position: "Software Arcitect",
        sales: "15,000",
      },
      {
        state: "danger",
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        position: "System Admin",
        sales: "23,000",
      },
      {
        state: "info",
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        position: "Account Manager",
        sales: "45,000",
      },
      {
        avatar: "media/avatars/300-23.jpg",
        name: "Dan Wilson",
        email: "dam@consilting.com",
        position: "Web Desinger",
        sales: "90,500",
      },
      {
        state: "danger",
        name: "Emma Bold",
        email: "emma@intenso.com",
        position: "Corporate Finance",
        sales: "5,000",
      },
      {
        avatar: "media/avatars/300-12.jpg",
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        position: "Customer Relationship",
        sales: "70,000",
      },
      {
        state: "primary",
        name: "Robert Doe",
        email: "robert@benko.com",
        position: "Marketing Executive",
        sales: "45,500",
      },
    ];

    return {
      users,
    };
  },
});
